<template>
  <div>
    <campaign></campaign>
  </div>
</template>

<script>
import Campaign from "./../../../components/bulksms/campaigns/index";
export default {
  data: () => ({
  }),
  components: {
    Campaign,
  },
};
</script>