<template>
  <div>
    <div class="text-h6 font-weight-medium mb-4">
      {{ $t("bulksms.campaign.title") }}
    </div>
    <v-tabs
      class="elevation-2 rounded-lg"
      height="55"
      v-model="tab"
      show-arrows
      slider-size="3"
    >
      <v-tabs-slider color="primary"></v-tabs-slider>
      <v-tab :to="$i18n.getRouteLink('bulksms')">
        <span
          :class="{
            'grey--text text--darken-2': tab !== '/bulksms/campaigns/',
          }"
          class="text-body-1 font-weight-bold"
          ><span class="secondary-font">{{
            $t("bulksms.campaign.simple.title")
          }}</span></span
        >
      </v-tab>
      <v-tab :to="$i18n.getRouteLink('bulksms-campaign-file')">
        <span
          :class="{ 'grey--text text--darken-2': tab !== '/bulksms/campaigns/file' }"
          class="text-body-1 font-weight-bold"
          ><span class="secondary-font">{{
            $t("bulksms.campaign.file.title")
          }}</span></span
        >
      </v-tab>
      <v-tab :to="$i18n.getRouteLink('bulksms-campaign-simple-list')">
        <span
          :class="{
            'grey--text text--darken-2': tab !== '/bulksms/campaigns/simple/list',
          }"
          class="text-body-1 font-weight-bold"
          ><span class="secondary-font">{{
            $t("bulksms.campaign.simple.list.title")
          }}</span></span
        >
      </v-tab>
      <v-tab :to="$i18n.getRouteLink('bulksms-campaign-file-list')">
        <span
          :class="{
            'grey--text text--darken-2': tab !== '/bulksms/campaigns/pro/list',
          }"
          class="text-body-1 font-weight-bold"
          ><span class="secondary-font">{{
            $t("bulksms.campaign.file.list.title")
          }}</span></span
        ></v-tab
      >
    </v-tabs>
    <div class="mt-4">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({
    tab: null,
  }),
};
</script>